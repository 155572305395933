* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.carousel {
  position: relative;
  margin: 0 auto;
  column-gap: 3rem;
}

.sample-slider [class^="swiper-button-"]::after {
  content: "";
}

:root {
  --swiper-theme-color: #000;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.7);
  border: 0.5px solid #a2a4a9;
  color: #001431;
}

.swiper-pagination-bullet-active {
  background: #fec36a;
}

.swiper-pagination-bullet {
  height: 1.8rem;
  width: 1.8rem;
}

.swiper-pagination {
  position: relative;
}

.swiper-slide-active .description-block {
  display: block;
}
.swiper-coverflow {
  min-height: 100vh;
}

.swiper-wrapper {
  margin-bottom: 100px;
  max-height: 100vh;
}
