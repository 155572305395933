@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');

@layer base {
  :root {
    --color-white: 0 0% 100%;
    --color-background: 0 0% 100%; /* hsl(0, 0%, 100%) */
    --color-foreground: 0 0% 100%; /* hsl(0, 0%, 100%) */
    --color-brand-primary: 216 100% 10%;
    --color-brand-primary-muted: 267 83% 39%;
    --color-brand-secondary: 36 99% 71%; /* hsl(36, 99%, 71%) */
    --color-brand-text-light: 0 0% 44%;
  }

  .dark {
    --color-background: 0 0% 4%; /* night-700 */
    --color-foreground: 0 0% 100%; /* white */
  }
}

html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
